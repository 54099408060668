import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";

import WebsiteOptimization from '../../assets/img/website-optimization.png';
import WebAppDevelopment from '../../assets/img/web-app-development.png';
import EcommerceSolution from '../../assets/img/ecommerce-solution.png';

import EbusinessCardBanner from '../../assets/img/ebc/ebc.png';

function Portfolio() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="portfolio"
        >
            {/* Meta Information */}
            <Helmet>
                <title>Portfolio - TN76 Digital: Crafting Seamless Web Experiences</title>
                <meta
                    name="description"
                    content="Explore TN76's portfolio showcasing website optimization projects, custom web app development solutions, and innovative electronic business card designs for modern networking."
                />
            </Helmet>

            {/* Portfolio Section */}
            <section id="portfolio" className="portfolio">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Portfolio</h2>
                        <p>Our Work Speaks for Itself</p>
                    </header>

                    <div className="row gy-4">
                        {/* Example 1 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div className="portfolio-details">
                                <img src={WebsiteOptimization} alt="Website Optimization Project" className="img-fluid" />
                                <div className="portfolio-info">
                                    <h3>Website Optimization Project</h3>
                                    <p>Increased performance and traffic by 50% through strategic optimizations.</p>
                                    <a href="/portfolio" className="read-more"><span>View Details</span> <i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        {/* Example 2 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div className="portfolio-details">
                                <img src={WebAppDevelopment} alt="Custom Web App Development" className="img-fluid" />
                                <div className="portfolio-info">
                                    <h3>Custom Web App Development</h3>
                                    <p>Developed a scalable app that solved client challenges and improved efficiency.</p>
                                    <a href="/portfolio" className="read-more"><span>View Details</span> <i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        {/* Example 3 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div className="portfolio-details">
                                <img src={EcommerceSolution} alt="E-Commerce Solution" className="img-fluid" />
                                <div className="portfolio-info">
                                    <h3>E-Commerce Solution</h3>
                                    <p>Launched a feature-rich online store with seamless payment integration.</p>
                                    <a href="/portfolio" className="read-more"><span>View Details</span> <i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        {/* Example 4 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div className="portfolio-details">
                            </div>
                        </div>


                        {/* Example 5 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div className="portfolio-details">
                                <img src={EbusinessCardBanner} alt="E-Commerce Solution" className="img-fluid" />
                                <div className="portfolio-info">
                                    <h3>Electronic Business Card Solutions</h3>
                                    <p>Designed and developed modern, customizable electronic business cards to elevate personal and business branding. Enhanced usability and accessibility across devices.</p>
                                    <a href="/ebusinesscard" className="read-more"><span>View Details</span> <i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
{/* Hidaya
                        <div>Discover more about Hidaya, a beautiful app featuring daily hadith and quotes. <a href="/Hidaya" target="_blank">Explore Hidaya here</a>.</div>
  */}

                        {/* Example 6 */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div className="portfolio-details">
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </motion.div>
    );
}

export default Portfolio;
