import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";

function TermsofService() {
    
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);



    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-page">

            {/* Dynamically set the meta description */}
            <Helmet>
                <title>Terms of Service - TN76 Digital: Crafting Seamless Web Experiences.</title>
            </Helmet>

            {/* termsofservice Section ======= */}

            <section id="termsofservice" className="termsofservice">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Terms of Service</h2>
                        <p>Terms of Service for TN76 Digital</p>
                        Last updated: December 2, 2024
                    </header>


                    <h2>1. Acceptance of Terms</h2>
                    <p>By using our website, you agree to comply with and be bound by these Terms of Service. If you do not agree, you may not use our site.</p>

                    <h2>2. Changes to Terms</h2>
                    <p>We reserve the right to modify these terms at any time. Updated terms will be posted on this page, and continued use of our site means acceptance of the revised terms.</p>

                    <h2>3. User Obligations</h2>
                    <p>Users agree not to misuse our services or interfere with other users' access. Prohibited activities include:</p>
                    <ul>
                        <li>Posting illegal or harmful content</li>
                        <li>Attempting to breach our security protocols</li>
                        <li>Using automated means to interact with the site</li>
                    </ul>

                    <h2>4. Intellectual Property</h2>
                    <p>All content, logos, and trademarks on this website are the property of [Your Company Name]. Unauthorized use of our intellectual property is prohibited.</p>

                    <h2>5. Limitation of Liability</h2>
                    <p>We are not liable for any damages arising from the use of our website or services. Users assume all risks associated with accessing our content.</p>
                    
                    <h2>6. Privacy Policy</h2>
                    <p>Our <a href="/privacy">Privacy Policy</a> outlines how we handle user information. By using our site, you agree to our data practices as described in the policy.</p>

                    <h2>7. Governing Law</h2>
                    <p>These terms are governed by the laws of California. Any disputes will be resolved in the jurisdiction of California.</p>

                    <h2>8. Contact Us</h2>
                    <p>If you have questions about these terms, please contact us at <a href="mailto:tounsils@gmail.com">tounsils@gmail.com</a>.</p>

                    <h2>9. Contact Us</h2>
                    <p><b>This business is registered with the <a href="https://www.cdtfa.ca.gov">California Department of Tax and Fee Administration</a></b>.</p>


                </div>

            </section>

            {/* End Privacy Section ======= */}

        </motion.div>
    );
}

export default TermsofService;
