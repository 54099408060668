import React from 'react';
import 'aos/dist/aos.css';
import Logo from '../assets/img/logo.png';
import letsencrypt from '../assets/img/let-s-encrypt.png';

function Footer() {

    const date = new Date();
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });

    return (


        <footer className="footer">

            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h4>Solution</h4>
                            <p>Ready to embark on your digital journey? Let's bring your vision to life. Reach out to us today for a free consultation.</p>
                        </div>
                        <div className="col-lg-12 text-center">
                            <a className="getstarted scrollto" href="/contactus">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>



            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <img src={Logo} alt="TN76 Digital" />
                                <span>TN76 Digital</span>
                            </a>

                            Don't be afraid! We'll walk you through the entire process, hold your hand as much as you need, and help you through all the technical aspects that may seem scary. We work best with clients who leave the heavy lifting to us.
                            <br />
                            <u>This business is registered with the California 
                            Department of Tax and Fee Administration.</u>

                            <div className="social-links mt-3">
                                <a href="https://twitter.com/tn76com" className="twitter">
                                    <i className="bi bi-twitter"></i></a>
                                <a href="https://www.linkedin.com/in/mohameditounsi/" className="linkedin">
                                    <i className="bi bi-linkedin bx bxl-linkedin"></i></a>

                                <a className="skype" href="skype:tounsi.med.ca">
                                    <i className="bi bi-skype"></i>
                                </a>

                                <a className="github" href="https://github.com/tounsils">
                                    <i className="bi bi-github"></i>
                                </a>

                                <a className="whatsapp" href="https://wa.me/+17604814120/?text=Hello, Please ">
                                    <i className="bi bi-whatsapp"></i>
                                </a>

                            </div>
                            <img width="120px" src={letsencrypt} alt="Let's Encrypt is a certificate authority (CA) " />

                            
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href="/services">Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/termsofservice">Terms of service</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/faq">F.A.Q</a></li>
                            </ul>
                            
                            <h4>Tools</h4>
                            <ul>

                                <li><i className="bi bi-chevron-right"></i> <a href="/qrcode">QR Code Generator</a></li>

                            </ul>


                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href="/services">Web Development</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/services">Marketing</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/ebusinesscard">e-Business Card</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            Carlsbad California USA
                            <br /><strong>Phone:</strong> +1 (760) 481-4120
                            <br /><strong>Email:</strong> <a href="mailto:tounsils@gmail.com?subject=Contacting through tn76.com">tounsils@gmail.com</a>

                            <div className="social-links mt-3">
                                <a href="https://twitter.com/tn76com" className="twitter">
                                    <i className="bi bi-twitter"></i></a>
                                <a href="https://www.linkedin.com/in/mohameditounsi/" className="linkedin">
                                    <i className="bi bi-linkedin bx bxl-linkedin"></i></a>

                                <a className="skype" href="skype:tounsi.med.ca">
                                    <i className="bi bi-skype"></i>
                                </a>

                                <a className="github" href="https://github.com/tounsils">
                                    <i className="bi bi-github"></i>
                                </a>

                                <a className="whatsapp" href="https://wa.me/+17604814120/?text=Hello, Please ">
                                    <i className="bi bi-whatsapp"></i>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
            

                <div className="copyright">
                    &copy; 2011-{new Date().getFullYear()} Copyright. All Rights Reserved
                    <br /><span>Web and Carto tn76.com</span>
                </div>
                <div className="credits">
                    Made with <i className="fas fa-heart" style={{ color: 'red' }}></i> by TN76 Digital using React <i className="ri-reactjs-line"></i>
                    <br />
                    Version 1.5
                    <br />

                    <a href="https://stackoverflow.com/users/10537019/mohamed-tounsi?tab=profile" className="sb-stackoverflow">
                        Mohamed I Tounsi (Stack-Overflow)</a>
                    <br />
                    <a href="https://github.com/tounsils">
                        <span className="bi bi-github"></span> tounsils</a>
                    <br />
                    Last update {monthYear}


                </div>
            </div>




        </footer>



    );
}

export default Footer;
