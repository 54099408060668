// src\components\Hero.js

import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

import heroImg from '../assets/img/hero-img.png';

function Hero() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);
    
    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-page"
        >

            {/* Hero Section ======= */}

            <section id="hero" className="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Reliable Website Maintenance & Innovative Web App Solutions.</h1>
                            <h2 data-aos="fade-up" data-aos-delay="400">
                            Empowering businesses by ensuring website performance and crafting bespoke web applications.
                            </h2>

                            <div data-aos="fade-up" data-aos-delay="600" className="justify-content-center">
                                <div className="text-center text-lg-start">
                                    <a
                                        href="/contactus"
                                        className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                                    >
                                        <span>Web App Consultation</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                    <img src={heroImg} className="img-fluid" alt="" />
                </div>

                    </div>
                </div>
            </section>

            {/* End Hero ======= */}


        </motion.div>

    );
}

export default Hero;
