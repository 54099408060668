import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";

function Privacy() {
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);



    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-page">

            {/* Dynamically set the meta description */}
            <Helmet>
                <title>Privacy - TN76 Digital: Crafting Seamless Web Experiences.</title>
            </Helmet>


            {/* Privacy Section ======= */}

            <section id="privacy" className="privacy">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Privacy Policy</h2>
                        <p>Privacy Policy for TN76 Digital</p>
                        Last updated: October 31, 2024
                    </header>

                    <p>At TN76 Digital, accessible from our app, we prioritize the privacy of our users. This privacy policy document contains types of information that is collected and recorded by TN76 Digital and how we use it.</p>

                    <h2>Information We Collect</h2>
                    <p>TN76 Digital collects personal information when you use our services. This includes your name, email address, and other information you may provide when registering or booking spaces. We also collect data on your interactions with the app, such as your IP address, device information, and usage statistics.</p>

                    <h2>How We Use Your Information</h2>
                    <p>We use the collected information for various purposes, including:</p>
                    <ul>
                        <li>To provide and maintain our services</li>
                        <li>To notify you about changes to our services</li>
                        <li>To allow you to participate in interactive features of our app</li>
                        <li>To provide customer support</li>
                        <li>To monitor the usage of our services</li>
                        <li>To detect, prevent and address technical issues</li>
                    </ul>

                    <h2>Sharing Your Information</h2>
                    <p>We do not share your personal information with third parties except to comply with legal obligations, protect our rights, or provide services with third-party providers (such as payment processors) that are integral to the functioning of TN76 Digital.</p>

                    <h2>Security of Your Information</h2>
                    <p>We take the security of your personal information seriously and use industry-standard encryption and security protocols to safeguard it. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h2>Third-Party Services</h2>
                    <p>Our app may contain links to third-party services that are not operated by us. If you click on a third-party link, you will be directed to their site. We advise you to review the privacy policy of every site you visit as we are not responsible for the content, privacy policies, or practices of third-party sites.</p>

                    <h2>Your Data Rights</h2>
                    <p>Depending on your location, you may have the right to request access to the personal information we have about you, to request that we correct or delete your personal data, and to object to certain data processing practices. To exercise these rights, you can contact us at the email below.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions about this privacy policy, please contact us at:</p>
                    <p>Email: tounsils@gmail.com</p>

                    <p>This privacy policy was last updated on October 31, 2024.</p>
                </div>

            </section>

            {/* End Privacy Section ======= */}

        </motion.div>
    );
}

export default Privacy;
